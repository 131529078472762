import { ReactNode } from "react";
import { NonNeverKeys } from "../../types/typescript";

export const layoutZones = [
  "video",
  "top",
  "left",
  "right",
  "bottom",
  "overlay",
  "extra",
] as const;

/** A zone defines a possible placment for a panel in the layout.
 *  This will affect positioning, zindexs and exclusion logic (as well as solo, stubborn...).
 */
export type LayoutZone = (typeof layoutZones)[number];

export const panelNames = [
  "actionBar",
  "logo",
  "social",
  "infocard",
  "language",
  "settings",
  "map",
  "popup",
  "profile",
  "cinematicView",
  "photo",
  "videoCapture",
  "mediaShare",
  "ending",
  "screenSharing",
  "videoAvatars",
  "hint",
  "questHint",
  "stats",
  "report",
  "devOptions",
  "presentationBar",
  "fullscreenVideo",
  "forceLandscape",
  "startButton",
  "poll",
  "textChatPreview",
  "walletConnect",
  "quest",
  "mobileController",
  "actionElements",
] as const;

export const panelSubElementsNames = [
  "actionBar/social",
  "actionBar/emojis",
  "actionBar/reactionsBar",
  "actionBar/movements",
  "actionBar/map",
  "actionBar/settings",
  "actionBar/photo",
  "cinematicView/skip",
  "fullscreenVideo/skip",
] as const;

export const socialPanelSubPagesNames = [
  "social/chat",
  "social/players",
  "social/playerProfile/:playerId",
] as ["social/chat", "social/players", `social/playerProfile/${string}`];

export const settingsPanelSubPagesNames = [
  "settings/home",
  "settings/about",
  "settings/video",
  "settings/controls",
  "settings/streamDiffusion",
  "settings/walletconnect",
] as const;

export const panelSubPagesNames = [
  ...socialPanelSubPagesNames,
  ...settingsPanelSubPagesNames,
] as const;

export type PanelExtraOptions = {
  social: never;
  settings: never;
  presentationBar: never;
  photo: {
    switchTab: boolean;
  };
  videoCapture: {
    switchTab: boolean;
  };
  mediaShare: never;
  actionBar: never;
  logo: never;
  infocard: never;
  language: never;
  map: never;
  popup: {
    link?: string;
    extraText?: string;
  };
  profile: never;
  forceLandscape: never;
  cinematicView: never;
  ending: never;
  screenSharing: {
    minimized: boolean;
  };
  videoAvatars: never;
  hint: {
    content: string;
  };
  questHint: {
    slug: string;
  };
  stats: never;
  devOptions: never;
  report: never;
  fullscreenVideo: never;
  startButton: never;
  poll: never;
  textChatPreview: never;
  walletConnect: never;
  quest: never;
  mobileController: never;
  actionElements: never;
};

export type PanelSubPages = {
  social: SocialPanelSubPageName;
  settings: SettingsPanelSubPageName;
  photo: never;
  presentationBar: never;
  videoCapture: never;
  mediaShare: never;
  actionBar: never;
  logo: never;
  infocard: never;
  language: never;
  map: never;
  popup: never;
  profile: never;
  forceLandscape: never;
  cinematicView: never;
  ending: never;
  screenSharing: never;
  videoAvatars: never;
  hint: never;
  questHint: never;
  stats: never;
  devOptions: never;
  report: never;
  fullscreenVideo: never;
  startButton: never;
  poll: never;
  textChatPreview: never;
  walletConnect: never;
  quest: never;
  mobileController: never;
  actionElements: never;
};

export type PanelsWithSubPages = NonNeverKeys<PanelSubPages>;
export type PanelsWithExtraOptions = NonNeverKeys<PanelExtraOptions>;
export const panelsWithSubPages: PanelsWithSubPages[] = ["social", "settings"];
export type PanelName = (typeof panelNames)[number];
export type PanelSubElementName = (typeof panelSubElementsNames)[number];
export type AllPanelSubPagesNames = (typeof panelSubPagesNames)[number];
export type SocialPanelSubPageName = (typeof socialPanelSubPagesNames)[number];
export type SettingsPanelSubPageName =
  (typeof settingsPanelSubPagesNames)[number];

export type PanelState<TPanelName extends PanelName> = {
  // ------ Visibility options
  name: TPanelName;
  /** If the panel is ready to be mounted in the UI. */
  readyForMount?: boolean;
  /** If the panel should be currently visible in the UI. */
  visible?: boolean;
  /** If the panel should become visible if not other panel is preventing it. */
  active?: boolean;
  /** Order of visibility for panels in the same zone. */
  priority?: number;
  /** If the panel should be visible in certain conditions. */
  rules: PanelRule[];

  // ------ Layout options

  /** The panel placment in the layout. If no zone is assigned, the panel will be hidden.
   *  While opening the panel will hide the previously opened panel in the same zone. */
  zone?: LayoutZone;

  // ------ Content options

  // Note, not all panels implements all these content options.
  // Only the slug option should be exposed to the Game throgh the game messages API.
  // If the game wants to target a specific subpage or subElement, it does so by treating it as a
  // top level panel that it want to open/close.

  /** Some panels may displays specific content related to CMS ids (e.g. popups). */
  slug?: string;
  /** Some panels may be made of multiple subpages that are exclusionary to eachother (e.g. settings).
   *  The naming for each subpage HAS TO BE "myPanel/mySubpage".
   *  When opening a panel alone (wihtout specifying the subpage) we open the default subpage (if any).
   */
  subpage?: PanelSubPages[TPanelName];
  /** Some panels might have sub elements that might be individually shown/hidden (e.g. actionBar).
   *  The naming for each sub element HAS TO BE "myPanel/mySubElement".
   */
  // TODO do the same type narrowing for subelement
  subElements?: PanelSubElementName[];

  // ------ Other options
  options?: PanelExtraOptions[TPanelName];
};

export function hasPanelRule<PanelKey extends PanelName>(
  panel: PanelState<PanelKey>,
  rule: PanelRule
) {
  return panel.rules.includes(rule);
}

/** Information about how te panel should behave in the layout. */
export type PanelLayoutOptions<PanelKey extends PanelName> = Pick<
  PanelState<PanelKey>,
  "zone" | "rules"
>;

/** This are the extra content options that can be specified when opening
 *  close a panel. "subpage" and "subElements" are not included here because
 *  they are specified directly with the provided target name.
 */
export type PanelContentOptions<TPanelName extends PanelName> = Pick<
  PanelState<TPanelName>,
  "slug" | "options"
>;
/** While opened clears all other panels in all zones.
 *solo
 * Prevents the panel from being hidden by other panels.
 * - The panel can only be closed/hidden manually.
 * - The panel, once opened, will prevent other panels in his same zone (even other stubborn ones) from opening.
 * - The panel will not hide if a "solo" panels opens in another zone.
 *
 * stubborn
 * Ignores all panel/hiding logic.
 *  All other panels ignoers him (even the solo and stubborn ones).
 ghost?: boolean;
 */

export type PanelRule =
  | "global"
  | "experienceOnly"
  | "solo"
  | "stubborn"
  | "ghost";

/** A payload to setup the responsive behaviour and render of a panel  */
export type PanelSettings<PanelKey extends PanelName = PanelName> = {
  component: ReactNode;
  name: PanelName;
  normal: PanelLayoutOptions<PanelKey>;
  small?: PanelLayoutOptions<PanelKey>;
};

export const isPanelWithSubPages = (
  name: PanelName | AllPanelSubPagesNames | PanelSubElementName
): name is PanelsWithSubPages => {
  return panelsWithSubPages.includes(name as PanelsWithSubPages);
};

export const isPanelName = (
  name: PanelName | AllPanelSubPagesNames | PanelSubElementName | string
): name is PanelName => {
  return panelNames.includes(name as PanelName);
};

export const matchPanelName = (
  name: PanelName | AllPanelSubPagesNames | PanelSubElementName
): PanelName | AllPanelSubPagesNames | PanelSubElementName | undefined => {
  const nonTemplateNames = panelSubPagesNames.filter(
    (name) => !name.includes(":")
  );
  if (nonTemplateNames.includes(name as AllPanelSubPagesNames)) {
    return name as AllPanelSubPagesNames;
  }
  const templateNames = panelSubPagesNames.filter((name) => name.includes(":"));
  return templateNames.find((templateName) => {
    const templateNameParts = templateName.split("/");
    const nameParts = name.split("/");
    if (templateNameParts.length !== nameParts.length) {
      return false;
    }
    return templateNameParts.every((part, index) => {
      return part === nameParts[index] || part.includes(":");
    });
  });
};

export const isPanelSubPageName = (
  name: PanelName | AllPanelSubPagesNames | PanelSubElementName
): name is AllPanelSubPagesNames => {
  const matchedPanelName = matchPanelName(name);
  return Boolean(matchedPanelName);
};

export const isPanelSubElementName = (
  name: PanelName | AllPanelSubPagesNames | PanelSubElementName
): name is PanelSubElementName => {
  return panelSubElementsNames.includes(name as PanelSubElementName);
};

export const isPanelTargetName = (
  name: string
): name is PanelName | PanelSubElementName | AllPanelSubPagesNames => {
  return (
    panelNames.includes(name as PanelName) ||
    panelSubElementsNames.includes(name as PanelSubElementName) ||
    panelSubPagesNames.includes(name as AllPanelSubPagesNames)
  );
};
