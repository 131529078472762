import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GyaradosAxios } from "@journee-live/gyarados";
import throttle from "lodash/throttle";
import { v4 as uuidv4 } from "uuid";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { StreamingStatus } from "../../app/gameConnection/gameConnectionTypes";
import {
  getQueryStrings,
  updateQueryString,
} from "../../app/routing/routingUtils";
import { useStore } from "../../app/store";
import { logError } from "../../common/util/logger";
import { getNextCalendarEvent, isCalendarOpen } from "./loginUtils";

/** If not already present in the local storage,
 * it creates a unique id, which will be persisted
 * in the local storage and that will identify the
 * current client device+browser in the future. */
export const useApplyClientPersistedId = () => {
  const clientPersistedId = useStore((s) => s.session.clientPersistedId);
  const setClientPersistedId = useStore((s) => s.session.setClientPersistedId);
  useEffect(() => {
    if (!clientPersistedId) setClientPersistedId(uuidv4());
  }, [clientPersistedId, setClientPersistedId]);
};

type validatePreExistingTokensReturn = {
  token?: string;
  error?: Error;
  isLoading: boolean;
  isRestricted: boolean;
};

export const useValidatePreExistingTokens = (
  existingToken: string | null
): validatePreExistingTokensReturn => {
  const navigate = useNavigate();
  const { environmentId } = useEnvironmentContext();
  const { jwt, ssoCode } = getQueryStrings();
  const [queryToken, setQueryToken] = useState<string>();
  const [ssoToken, setSsoToken] = useState<string>();
  const [newToken, setNewToken] = useState<string>();
  const [isExistingTokenChecked, setIsExistingTokenChecked] = useState(false);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => {
    if (jwt) {
      setQueryToken(jwt);
      updateQueryString(navigate, "jwt");
      setIsLoading(false);
    } else if (ssoCode) {
      const visitorApi = new GyaradosAxios.VisitorsApi(
        undefined,
        import.meta.env.VITE_GYARADOS_URL
      );
      visitorApi
        .visitorsControllerGetToken({ body: { code: ssoCode } })
        .then(({ data }) => {
          setSsoToken(data.token);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error as Error);
        })
        .finally(() => {
          updateQueryString(navigate, "ssoCode");
        });
    } else if (!queryToken && !ssoToken && !isExistingTokenChecked) {
      fetch(
        `${import.meta.env.VITE_GYARALESS_URL}/check-token/${environmentId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${existingToken}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then(({ token, error }) => {
          if (token) {
            setNewToken(token);
          } else if (error == "Visitor is restricted") {
            setIsRestricted(true);
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsExistingTokenChecked(true);
        });
    } else {
      setIsLoading(false);
    }
  }, [
    environmentId,
    isExistingTokenChecked,
    jwt,
    queryToken,
    navigate,
    ssoCode,
    ssoToken,
    existingToken,
  ]);

  // The returned visitorToken is not guaranteed to be valid (but it's likely).
  // It will be checked later on when attempting to connect to an instance.

  // The hierarchy between auth methods should be:
  // 1. JWT token in query (to enable moderator login).
  // 2. SSO code in query (to respect the SSO flow).
  // 3. Token from cookie.
  return {
    token: queryToken ?? ssoToken ?? newToken ?? undefined,
    error,
    isLoading,
    isRestricted,
  };
};

export const useValidateOpeningTimes = () => {
  const { environment } = useEnvironmentContext();
  const events = environment?.calendarEvents;
  const hasEvents = Boolean(events);
  const [hasOpenSlot, setHasOpenSlot] = useState(false);
  const [willSoonRedirect, setWillSoonRedirect] = useState(false);

  useEffect(() => {
    if (!hasEvents) return;
    const isOpenNow = isCalendarOpen(events);
    setHasOpenSlot(isOpenNow);
  }, [hasEvents, events]);

  useEffect(() => {
    let intervalId: number | undefined;

    if (intervalId) clearInterval(intervalId);
    if (!events) return;
    if (!intervalId)
      intervalId = window.setInterval(() => {
        const isOpenNow = isCalendarOpen(events);
        setHasOpenSlot(isOpenNow);
        if (hasOpenSlot && !isOpenNow) {
          setWillSoonRedirect(true);
          clearInterval(intervalId);
        }
        // Important: don't check too often, or everyone will get redirected
        // at the same time and it would nuke the redirection URL by DDoS.
      }, 20000);

    return () => clearInterval(intervalId);
  }, [events, hasOpenSlot]);

  const nextEvent =
    hasEvents && !hasOpenSlot ? getNextCalendarEvent(events) : undefined;
  const previousEvent =
    hasEvents && !nextEvent ? events[events.length - 1] : undefined;

  return {
    nextEvent,
    previousEvent,
    isOpen: hasEvents ? hasOpenSlot : true,
    isClosing: willSoonRedirect,
  };
};

export const useInactivityCheck = () => {
  const { environment } = useEnvironmentContext();
  const [isInactive, setIsInactive] = useState(false);
  const overrideInactivityTimeout = useStore(
    (s) => s.session.overrideInactivityTimeout
  );
  const hasInactivityExceptions = useStore(
    (s) => Object.keys(s.session.inactivityExceptions).length > 0
  );
  const isConnected = useStore(
    (s) =>
      s.gameConnection.streamingStatus != null &&
      s.gameConnection.streamingStatus !== StreamingStatus.INIT
  );
  let inactivityKickout = environment?.inactivityKickout;
  if (overrideInactivityTimeout && inactivityKickout)
    inactivityKickout = Math.max(inactivityKickout, overrideInactivityTimeout);

  const isDevRoute = window.location.pathname.includes("/_dev");
  const isIFrame = window.location.host.startsWith("embed.");

  const shouldCheckInactivity = useRef(false);
  shouldCheckInactivity.current =
    !isDevRoute && !isIFrame && isConnected && !hasInactivityExceptions;

  const handleInactivity = useCallback(() => {
    if (shouldCheckInactivity.current) {
      setIsInactive(true);
    }
  }, []);

  useEffect(() => {
    let timer: number;

    const resetTimer = throttle(() => {
      if (inactivityKickout) {
        clearTimeout(timer);
        setIsInactive(false);
        timer = window.setTimeout(handleInactivity, inactivityKickout);
      }
    }, 1000);

    window.addEventListener("load", resetTimer, true);
    window.addEventListener("mousemove", resetTimer, true);
    window.addEventListener("mousedown", resetTimer, true);
    window.addEventListener("touchstart", resetTimer, true);
    window.addEventListener("click", resetTimer, true);
    window.addEventListener("keydown", resetTimer, true);
    window.addEventListener("scroll", resetTimer, true);
    // As soon as we mount/enable the timer, we should kickoff inactivity
    // measurements. Otherwise, the user can click sign in and leave the instance
    // running in a background tab.
    resetTimer();

    return () => {
      resetTimer.cancel();
      clearTimeout(timer);
      window.removeEventListener("load", resetTimer, true);
      window.removeEventListener("mousemove", resetTimer, true);
      window.removeEventListener("mousedown", resetTimer, true);
      window.removeEventListener("touchstart", resetTimer, true);
      window.removeEventListener("click", resetTimer, true);
      window.removeEventListener("keydown", resetTimer, true);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [inactivityKickout, handleInactivity]);

  return { isInactive };
};

export const useLogin = (
  environmentId: string,
  set: (token: string) => void
) => {
  useEffect(() => {
    const login = async () => {
      window.analytics?.track("login", {
        type: "login",
        name: "open",
      });

      try {
        const { token, error }: { token?: string; error?: string } =
          await fetch(
            `${import.meta.env.VITE_GYARALESS_URL}/login/${environmentId}`,
            {
              method: "POST",
              body: JSON.stringify({}),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((response) => response.json());
        if (!token) throw new Error(error);
        set(token);
      } catch (error: unknown) {
        logError("USER_FLOW", error);
      }
    };
    login();
  }, [environmentId, set]);
};
