import { useReflow } from "../../../../common/hooks/ui";
import SettingsHomePageUiLandscape from "./SettingsHomePage.ui.landscape";
import SettingsHomePageUiNormal from "./SettingsHomePage.ui.normal";
import SettingsHomePageUiPortrait from "./SettingsHomePage.ui.portrait";

export type SettingName =
  | "about"
  | "controls"
  | "profile"
  | "streamDiffusion"
  | "volume"
  | "video"
  | "mic"
  | "fullscreen"
  | "walletConnect"
  | "walletModal";

export type Props = {
  inactive?: SettingName[];
  disabled?: SettingName[];
  hidden?: SettingName[];
  itemHeight?: React.CSSProperties["height"];
  onClick?: (name: SettingName) => void;
};

const SettingsHomePageUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <SettingsHomePageUiNormal {...props} />,
    landscape: <SettingsHomePageUiLandscape {...props} />,
    portrait: <SettingsHomePageUiPortrait {...props} />,
  });
};

export default SettingsHomePageUi;
