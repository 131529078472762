import { Props } from "./MobileController.ui";
import ActionBarButtons from "./component/ActionBarButtons";
import CameraKeys from "./component/CameraKeys";
import MovementKeys from "./component/MovementKeys";

const MobileControllerUiLandscape: React.FC<Props> = ({
  onTouchStart,
  onTouchEnd,
  onPhotoClick,
  onSettingsClick,
  hideActionBarButtons,
}) => {
  return (
    <>
      <MovementKeys onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} />
      <CameraKeys onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} />
      <ActionBarButtons
        visible={!hideActionBarButtons}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onPhotoClick={onPhotoClick}
        onSettingsClick={onSettingsClick}
      />
    </>
  );
};

export default MobileControllerUiLandscape;
