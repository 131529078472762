import { useEffect, useMemo } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { MessageType } from "../../app/gameConnection/webrtc/helpers/constants";
import { sendInputData } from "../../app/gameConnection/webrtc/webRtcConnection";
import { useStore } from "../../app/store";

/**
 *  Hardcoded logic for the Diamond Demo.
 *  Remove as soon as we have a better generic solution
 *  or this will turn into another web-client :disappointed:
 */
export const useDiamondDemo = () => {
  const { environment } = useEnvironmentContext();
  const openPanel = useStore((state) => state.layout.openPanel);
  const setGameSessionEnding = useStore((s) => s.session.setGameSessionEnding);
  const isGameReady = useStore((s) => s.gameConnection.gameIsReady);
  const isExperienceReady = useStore(
    (s) => s.userFlow.currentStep === "experience:ready"
  );
  const { muted } = useStore((s) => s.userMedia);
  const isDiamondDemo = environment.projectId === 33191;
  const onboardingInfoCard = environment.infoCards?.find(
    (it) => it.infoCardId === "onboarding"
  );
  const isStableDiffusionEnabled = (
    environment.worldModelSettings as { enableSd: boolean } | undefined
  )?.enableSd;

  // Show the onboarding infocard
  useEffect(() => {
    if (!isDiamondDemo || !onboardingInfoCard) return;
    openPanel("infocard", { slug: "onboarding" });
  }, [isDiamondDemo, onboardingInfoCard, openPanel]);

  // Reset the game by sending Enter command
  useEffect(() => {
    if (!isDiamondDemo || !isGameReady || !isExperienceReady) return;
    sendInputData(new Uint8Array([MessageType.KeyDown, 13, 0]).buffer);
    sendInputData(new Uint8Array([MessageType.KeyUp, 13]).buffer);
  }, [isDiamondDemo, isGameReady, isExperienceReady]);

  // Audio
  const audio = useMemo(() => {
    if (!isDiamondDemo || !onboardingInfoCard) return;
    const hasAdamJelly =
      onboardingInfoCard.description?.includes("Adam Jelley");
    if (!hasAdamJelly) return;
    const audio = new Audio(
      "https://staging-gyarados-medias.s3.eu-central-1.amazonaws.com/1/1/33191/counter_xyz_music.mp3"
    );
    audio.muted = false;
    audio.defaultMuted = false;
    audio.loop = true;
    audio.volume = 1;
    audio.autoplay = false;
    audio.id = "demoAudio";
    return audio;
  }, [isDiamondDemo, onboardingInfoCard]);

  // Mute the audio from settings
  useEffect(() => {
    if (!audio) return;
    audio.muted = muted;
  }, [audio, muted]);

  // Register to start on user interaction
  useEffect(() => {
    if (!audio) return;
    const playExperienceAudioHandler = () => {
      if (audio.paused) audio.play();
    };
    window.addEventListener(
      "play-experience-audio",
      playExperienceAudioHandler
    );
    return () => {
      window.removeEventListener(
        "play-experience-audio",
        playExperienceAudioHandler
      );
      audio.pause();
    };
  }, [audio]);

  // Max duration of gameplay
  useEffect(() => {
    if (!isDiamondDemo || !isGameReady || !isExperienceReady) return;
    const maxDuration = 60 * 60 * 1000; // 1 hour
    const timeout = setTimeout(() => {
      setGameSessionEnding(true);
    }, maxDuration);
    return () => clearTimeout(timeout);
  }, [isDiamondDemo, isGameReady, isExperienceReady, setGameSessionEnding]);

  // Stable diffusion
  useEffect(() => {
    if (!isExperienceReady || !isStableDiffusionEnabled) return;
    openPanel("settings/streamDiffusion");
  }, [isExperienceReady, isStableDiffusionEnabled, openPanel]);
};
