export const KEY_FORWARD = 87;
export const KEY_LEFT = 65;
export const KEY_BACK = 83;
export const KEY_RIGHT = 68;
export const KEY_ENTER = 13;

export const KEY_ARROW_UP = 38;
export const KEY_ARROW_LEFT = 37;
export const KEY_ARROW_DOWN = 40;
export const KEY_ARROW_RIGHT = 39;
