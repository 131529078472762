import { styled } from "styled-components";
import { steps } from "../../app/style/theme";
import { getImageUrl } from "../../common/util/gyarados.util";
import Image from "../../componentsLibrary/atoms/Image";
import Space from "../../componentsLibrary/atoms/Space";
import CardPartialPortrait from "../../componentsLibrary/molecules/CardPartialPortrait";
import { Props } from "./Poll.ui";
import useGetPollElements from "./hooks/useGetPollElements";

const PollContainer = styled.div`
  padding: 0 ${steps.spacing[6]};
`;

const PollUiPortait: React.FC<Props> = ({
  handleDismiss,
  poll,
  testId,
  ...rest
}) => {
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, ...rest });
  const pollImage = poll?.image;
  return (
    <CardPartialPortrait
      testId={testId}
      padding="0px"
      topSection={
        <>
          {pollImage ? (
            <Image
              src={getImageUrl(pollImage, 700, 700) ?? ""}
              width="100%"
              height="200px"
              cover
            />
          ) : (
            <PollContainer>
              <Space h={8} />
              {Header}
            </PollContainer>
          )}
          <PollContainer>
            <Space h={6} />
            {Title}
          </PollContainer>
        </>
      }
      onClose={handleDismiss}
    >
      <PollContainer>
        <Space h={5} />
        {Subtitle}
        {Body}
        {Button}
        <Space h={2} />
        {ErrorMessage}
        <Space h={8} />
      </PollContainer>
    </CardPartialPortrait>
  );
};

export default PollUiPortait;
