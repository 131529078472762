/* eslint-disable react-hooks/exhaustive-deps,i18next/no-literal-string */
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { steps } from "../../../../app/style/theme";
import useLocalStorage from "../../../../common/hooks/localStorage";
import Checkbox from "../../../../componentsLibrary/atoms/Checkbox";
import Column from "../../../../componentsLibrary/atoms/Column";
import InputText from "../../../../componentsLibrary/atoms/InputText";
import Row from "../../../../componentsLibrary/atoms/Row";
import Space from "../../../../componentsLibrary/atoms/Space";
import SelectText from "../../../../componentsLibrary/molecules/SelectText";

const Content = styled.div`
  padding-right: max(var(--safe-margin-right), 64px);
`;

const defaultPrompts = ["Cyberpunk", "A dystopian future", "Anime"];
const defaultNegativePrompts = ["low quality", "bad", "unrealistic"];
const strengthOptions = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map(
  (v) => ({ value: v.toString(), label: v.toFixed(1) })
);
const guidanceOptions = [
  0, 0.1, 0.2, 0.3, 0.5, 0.7, 1, 1.2, 1.5, 1.8, 2, 2.5, 3.0, 3.5, 4, 5, 6, 7, 8,
].map((v) => ({ value: v.toString(), label: v.toFixed(1) }));
const Label = styled.label`
  font-family: ${(p) => p.theme.fontMain}, ${(p) => p.theme.fontMainFallback};
  font-size: ${steps.font.f10.size};
  color: ${(p) => p.theme.colorAbove3};
  pointer-events: none;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelectText = styled(SelectText)`
  width: 100%;
  text-align: start;
  span {
    color: ${(p) => p.theme.colorAbove4};
    font-weight: unset;
    font-size: ${steps.font.f20.size};
  }
  & > span:hover span {
    color: ${(p) => p.theme.colorAbove3};
  }
`;

const SettingsStreamDiffusionPageUi: React.FC = () => {
  const { environment } = useEnvironmentContext();
  const isEnabledByEnvironment = (
    environment.worldModelSettings as { enableSd: boolean }
  )?.enableSd;
  const [prompt, setPrompt] = useLocalStorage<string>(
    "visitorPrompt",
    defaultPrompts[Math.floor(Math.random() * defaultPrompts.length)]
  );

  const [negativePrompt, setNegativePrompt] = useState<string>(
    defaultNegativePrompts.join(",")
  );
  const [enabled, setEnabled] = useState<boolean>(isEnabledByEnvironment);
  // Fal's default for SDTurbo: https://www.fal.ai/camera
  // {num_inference_steps:3,strength:.44,guidance_scale:1,seed:6252023}
  const [strength, setStrength] = useState<number>(0.6);
  const [guidance, setGuidance] = useState<number>(0.5);
  const debounceTimer = useRef<number | null>(null);
  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = window.setTimeout(() => {
      onUpdate();
    }, 1000);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [strength, guidance, prompt, negativePrompt, enabled]);

  const onUpdate = () => {
    const options = {
      num_inference_steps: 50,
      guidance_scale: guidance,
      enabled,
      prompt,
      negative_prompt: negativePrompt,
      delta: strength,
    };
    sendGameMessage({
      type: "StreamDiffusionSettings",
      data: options,
    });
  };

  return (
    <Content>
      <Checkbox
        label="Enabled"
        name="enabled"
        checked={enabled}
        onChange={({ checked }) => setEnabled(checked)}
      />
      <Column gap={4}>
        <InputText
          label="Prompt"
          name="prompt"
          noForm
          value={prompt}
          onChange={setPrompt}
        />
        <InputText
          label="Negative Prompt"
          name="negativePrompt"
          noForm
          value={negativePrompt}
          onChange={setNegativePrompt}
        />
      </Column>
      <Space h={5} />
      <Row justify="space-between">
        <DropdownWrapper>
          <Label>Strength</Label>
          <StyledSelectText
            onSelect={(value) => setStrength(parseFloat(value))}
            selected={strength.toString()}
            options={strengthOptions}
            dontPad
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <Label>Guidance</Label>
          <StyledSelectText
            onSelect={(value) => setGuidance(parseFloat(value))}
            selected={guidance.toString()}
            options={guidanceOptions}
            dontPad
          />
        </DropdownWrapper>
      </Row>
    </Content>
  );
};

export default SettingsStreamDiffusionPageUi;
