import styled from "styled-components";
import { PanelTestIds } from "../../../common/constants/testIds.constant";
import Icon from "../../../componentsLibrary/atoms/Icon";
import DynamicButton from "../../../componentsLibrary/molecules/DynamicButton";
import { KEY_ENTER } from "../lib/constants";

type Props = {
  visible?: boolean;
  onTouchStart: (key: number) => void;
  onTouchEnd: (key: number) => void;
  onPhotoClick?: () => void;
  onSettingsClick?: () => void;
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: max(var(--safe-margin-top), 16px);
  right: max(var(--safe-margin-right), 16px);
`;

const ActionBarButtons: React.FC<Props> = ({
  visible,
  onTouchStart,
  onTouchEnd,
  onPhotoClick,
  onSettingsClick,
}) => {
  if (!visible) return;

  return (
    <Container>
      <DynamicButton
        focused
        onClick={() => {
          onTouchStart(KEY_ENTER);
          onTouchEnd(KEY_ENTER);
        }}
      >
        <Icon.Refresh inheritColor size="20px" />
      </DynamicButton>
      <DynamicButton focused onClick={onPhotoClick}>
        <Icon.CameraFilled inheritColor size="20px" />
      </DynamicButton>
      <DynamicButton
        testId={PanelTestIds.actionBar.settings}
        focused
        onClick={onSettingsClick}
      >
        <Icon.GearFilled inheritColor size="20px" />
      </DynamicButton>
    </Container>
  );
};

export default ActionBarButtons;
