import { styled } from "styled-components";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useStore } from "../../app/store";
import { isTouch } from "../../common/constants/flags.constant";
import { BaseLayout } from "../_layout/PanelsLayout";
import MobileControllerLogic from "./MobileController.logic";
import { onScreenControlsType } from "./lib/types";

const Layout = styled(BaseLayout)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const MobileControllerPanel: React.FC = () => {
  const {
    environment: { onScreenControls },
  } = useEnvironmentContext();
  const photoPanelVisible = useStore((s) => s.layout.panels.photo.visible);

  return (
    <Layout id="panel-mobileController">
      <MobileControllerLogic
        visible={isTouch && (onScreenControls as onScreenControlsType)?.enable}
        hideActionBarButtons={photoPanelVisible}
      />
    </Layout>
  );
};

export default MobileControllerPanel;
