import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { sendKeyDown, sendKeyUp } from "../../app/gameConnection/useKeyboard";
import { useStore } from "../../app/store";
import MobileControllerUi from "./MobileController.ui";

type Props = {
  visible?: boolean;
  hideActionBarButtons?: boolean;
};

const MobileControllerLogic: React.FC<Props> = ({
  visible,
  hideActionBarButtons,
}) => {
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const photoPanelVisible = useStore((s) => s.layout.panels.photo.visible);
  const settingsPanelVisible = useStore(
    (s) => s.layout.panels.settings.visible
  );
  const {
    environment: { allowPhotoCapture },
  } = useEnvironmentContext();

  const onTouchStart = (key: number) => {
    sendKeyDown(key, false);
  };
  const onTouchEnd = (key: number) => {
    sendKeyUp(key);
  };

  const onPhotoClick = () => {
    const defaultPanel = allowPhotoCapture ? "photo" : "videoCapture";
    photoPanelVisible ? closePanel(defaultPanel) : openPanel(defaultPanel);
  };
  const onSettingsClick = () => {
    settingsPanelVisible ? closePanel("settings") : openPanel("settings");
  };

  if (!visible) return null;

  return (
    <MobileControllerUi
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onPhotoClick={onPhotoClick}
      onSettingsClick={onSettingsClick}
      hideActionBarButtons={hideActionBarButtons}
    />
  );
};

export default MobileControllerLogic;
