import { SvgProps } from "../../types/svg";

const RefreshIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9333 2C16.3443 2 15.8667 2.477 15.8667 3.06667V5.53633C14.5367 3.684 12.38 2.53333 10 2.53333C6.85 2.53333 4.08633 4.54467 3.11933 7.54C2.93807 8.10033 3.2459 8.70267 3.80633 8.88267C4.36933 9.066 4.969 8.7556 5.14967 8.19633C5.83 6.08333 7.78 4.66667 10 4.66667C11.6833 4.66667 13.2087 5.485 14.1467 6.8H12.6667C12.0777 6.8 11.6 7.277 11.6 7.86667C11.6 8.45633 12.0777 8.93333 12.6667 8.93333H16.9333C17.5223 8.93333 18 8.45633 18 7.86667V3.06667C18 2.477 17.5233 2 16.9333 2ZM16.1933 11.12C15.6293 10.9387 15.0307 11.2471 14.85 11.8063C14.17 13.9167 12.22 15.3333 10 15.3333C8.31667 15.3333 6.79167 14.515 5.85333 13.2H7.33333C7.92233 13.2 8.4 12.723 8.4 12.1333C8.4 11.5437 7.92233 11.0667 7.33333 11.0667H3.06667C2.47767 11.0667 2 11.5437 2 12.1333V16.9333C2 17.523 2.47767 18 3.06667 18C3.65567 18 4.13333 17.523 4.13333 16.9333V14.4637C5.46333 16.3167 7.62 17.4667 9.97 17.4667C13.1183 17.4667 15.8833 15.4553 16.85 12.46C17.0633 11.9 16.7533 11.27 16.1933 11.12Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default RefreshIcon;
