import { useCallback, useEffect, useRef, useState } from "react";
import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useCurrentExperienceLogo } from "../../../app/hooks/gyarados.hook";
import { useStore } from "../../../app/store";
import {
  LOGO_MAX_HEIGHT,
  LOGO_MAX_WIDTH,
} from "../../../common/constants/configs.constant";
import useDynamicButtonMenuEmojis from "../../../common/hooks/useDynamicButtonMenuEmojis";
import { getImageUrl } from "../../../common/util/gyarados.util";
import IconMenu from "../../../componentsLibrary/molecules/dynamicButtonMenu/DynamicButtonMenuDrawer";
import { useVideoCapture } from "../hooks/useVideoCapture";
import { EncoderService } from "../lib/VideoEncoder.service";
import VideoCaptureUi from "./VideoCapture.ui";

type Props = {
  autocapture: "start" | "complete" | "cancel" | undefined;
  setAutocapture: (
    autocapture: "start" | "complete" | "cancel" | undefined
  ) => void;
};

const VideoCaptureLogic: React.FC<Props> = ({
  autocapture,
  setAutocapture,
}) => {
  const { environment } = useEnvironmentContext();
  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const switchTab = useStore(
    (s) => s.layout.panels.videoCapture.options?.switchTab
  );
  const setPanelExtraOptions = useStore(
    (state) => state.layout.setPanelExtraOptions
  );
  const setMediaShareState = useStore(
    (state) => state.mediaCapture.setMediaShareState
  );
  const isAutocapturing = autocapture !== undefined;
  const logo = useCurrentExperienceLogo();
  const logoUrl = getImageUrl(logo, LOGO_MAX_WIDTH, LOGO_MAX_HEIGHT);
  const capturedVideo = useRef<Blob | undefined>(undefined);
  const availableEmojis = useDynamicButtonMenuEmojis();
  const [isRecording, setIsRecording] = useState(false);
  const [hideUi, setHideUi] = useState(!switchTab);
  const hideTabs =
    environment.allowVideoCapture !== environment.allowPhotoCapture;

  const onCapture = useCallback(
    (blob: Blob) => {
      capturedVideo.current = blob;
      setMediaShareState({
        media: blob,
        previousPanel: isAutocapturing ? undefined : "videoCapture",
      });
      closePanel("videoCapture");
      setAutocapture(undefined);
      openPanel("mediaShare");
    },
    [openPanel, closePanel, setMediaShareState, setAutocapture, isAutocapturing]
  );

  const { isIntialized, worker, recorder } = useVideoCapture(
    window.__streamingVideo,
    window.__streamingAudio,
    onCapture
  );
  const [recordingTime, setRecordingTime] = useState(0);
  const intervalId = useRef<number>();

  useEffect(() => {
    if (isRecording) {
      intervalId.current = window.setInterval(
        () => setRecordingTime((previous) => previous + 1),
        1000
      );
    } else if (intervalId.current) {
      window.clearInterval(intervalId.current);
    }
    return () => {
      if (intervalId.current) window.clearInterval(intervalId.current);
    };
  }, [isRecording]);

  const startHandler = () => {
    window.analytics?.track("click", {
      type: "click",
      name: "video_capture",
    });
    sendGameMessage({
      type: "MediaCaptureEvent",
      mediaType: "video",
      event: "start",
    });
    if (worker.current) {
      if (!window.__streamingVideo || !window.__streamingAudio) {
        // TODO: Handle error
        return;
      }
      const { videoStream, audioStream } = EncoderService.getReadableStreams(
        window.__streamingVideo,
        window.__streamingAudio
      );
      worker.current?.postMessage(
        { type: "begin", data: { videoStream, audioStream } },
        [videoStream, audioStream]
      );
      setIsRecording(true);
    } else if (recorder.current) {
      recorder.current.start();
      setIsRecording(true);
    }
  };

  const stopHandler = () => {
    if (worker.current) {
      worker.current?.postMessage({ type: "end" });
    } else if (recorder.current) {
      recorder.current.stop();
    }
    setIsRecording(false);
    sendGameMessage({
      type: "MediaCaptureEvent",
      mediaType: "video",
      event: "complete",
    });
  };

  const closeHandler = () => {
    setPanelExtraOptions("photo", {
      switchTab: false,
    });
    setAutocapture(undefined);
    closePanel("videoCapture", { options: { switchTab: false } });
  };

  const changeTabHandler = () => {
    setAutocapture(undefined);
    closePanel("videoCapture", { options: { switchTab: true } });
    openPanel("photo", { options: { switchTab: true } });
  };

  // Start or stop recording based on the Unreal action
  useEffect(() => {
    if (!isAutocapturing) return;
    if (!isRecording && isIntialized && autocapture === "start") {
      startHandler();
    } else if (isRecording && autocapture === "complete") {
      stopHandler();
    } else if (isRecording && autocapture === "cancel") {
      closePanel("videoCapture");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocapture, isRecording, isIntialized]);

  // We fake the Hint and Grid fade when switching panels
  useEffect(() => {
    if (switchTab) setHideUi(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isAutocapturing && (
      <VideoCaptureUi
        onCapture={isRecording ? stopHandler : startHandler}
        nrOfReactions={availableEmojis.length}
        capturedVideo={capturedVideo.current}
        onClose={closeHandler}
        onChangeTab={changeTabHandler}
        logoUrl={logoUrl}
        isRecording={isRecording}
        recordingTime={recordingTime}
        reactions={
          <IconMenu
            isOpen
            focused
            standAlone
            items={availableEmojis}
            direction="up"
          />
        }
        hideUi={hideUi}
        hideTabs={hideTabs}
      />
    )
  );
};

export default VideoCaptureLogic;
