import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { isIos } from "../../../../common/constants/flags.constant";
import { useIsLandscape } from "../../../../common/hooks/ui";

export const hasAddressBar = (isLandscape: boolean) => {
  // Checking that Address bar is gone or no more than 20px tall.
  // The 20px tall exception is because iOS can have minimised address bars,
  // which are small but non-hidable on scroll.
  // iPad also has some unique behaviours which we are not currently taking into accout.
  // (So we don't swipe-up-to-fullcreen on iPad alltoghether).
  const takesFullscreenHeight = window.innerHeight + 20 >= window.screen.width;

  // This can be improved in the future to take other devices and edge
  // cases into consideration.
  return isIos && !takesFullscreenHeight && isLandscape;
};

/**
 *
 * @returns true if the device:
 *          - is in landscape orientation
 *          - and is running Safari on a non-iPad iOS device
 *          - and the window innerHeight is the same as the screen
 *            (meaning the address bar went away)
 *          and false otherwise (by default).
 */
export function useDetectAddressBar() {
  const [isAddressBarPresent, setIsAddressBarPresent] = useState(false);
  const isLandscape = useIsLandscape();

  useEffect(() => {
    const checkDimensions = debounce(
      () => {
        setIsAddressBarPresent(hasAddressBar(isLandscape));
      },
      100,
      { leading: true, trailing: true }
    );
    window.addEventListener("orientationchange", checkDimensions);
    window.addEventListener("scroll", checkDimensions);

    // Check once on mount for safety
    checkDimensions();
    return () => {
      window.removeEventListener("orientationchange", checkDimensions);
      window.removeEventListener("scroll", checkDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsAddressBarPresent, isLandscape, window.innerHeight]);

  return isAddressBarPresent;
}

export default useDetectAddressBar;
