import { Joystick } from "react-joystick-component";
import { IJoystickUpdateEvent } from "react-joystick-component/build/lib/Joystick";
import styled, { useTheme } from "styled-components";
import { Props } from "../MobileController.ui";
import {
  KEY_ARROW_DOWN,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
  KEY_ARROW_UP,
} from "../lib/constants";

const Container = styled.div`
  position: absolute;
  bottom: max(var(--safe-margin-bottom), 32px);
  right: max(var(--safe-margin-left), 16px);
  pointer-events: all;
`;

const CameraKeys: React.FC<Props> = ({ onTouchStart, onTouchEnd }) => {
  const theme = useTheme();

  const handleMove = (event: IJoystickUpdateEvent) => {
    onTouchEnd(KEY_ARROW_UP);
    onTouchEnd(KEY_ARROW_DOWN);
    onTouchEnd(KEY_ARROW_LEFT);
    onTouchEnd(KEY_ARROW_RIGHT);
    if (event.x !== null && event.y !== null) {
      // Calculate the angle in degrees
      const angle = (Math.atan2(event.y, event.x) * 180) / Math.PI;
      // Categorize based on angle ranges
      if (angle >= -22.5 && angle < 22.5) {
        onTouchStart(KEY_ARROW_RIGHT);
      } else if (angle >= 22.5 && angle < 67.5) {
        onTouchStart(KEY_ARROW_UP);
        onTouchStart(KEY_ARROW_RIGHT);
      } else if (angle >= 67.5 && angle < 112.5) {
        onTouchStart(KEY_ARROW_UP);
      } else if (angle >= 112.5 && angle < 157.5) {
        onTouchStart(KEY_ARROW_UP);
        onTouchStart(KEY_ARROW_LEFT);
      } else if (angle >= 157.5 || angle < -157.5) {
        onTouchStart(KEY_ARROW_LEFT);
      } else if (angle >= -157.5 && angle < -112.5) {
        onTouchStart(KEY_ARROW_DOWN);
        onTouchStart(KEY_ARROW_LEFT);
      } else if (angle >= -112.5 && angle < -67.5) {
        onTouchStart(KEY_ARROW_DOWN);
      } else if (angle >= -67.5 && angle < -22.5) {
        onTouchStart(KEY_ARROW_DOWN);
        onTouchStart(KEY_ARROW_RIGHT);
      }
    }
  };

  const handleReset = () => {
    onTouchEnd(KEY_ARROW_UP);
    onTouchEnd(KEY_ARROW_DOWN);
    onTouchEnd(KEY_ARROW_LEFT);
    onTouchEnd(KEY_ARROW_RIGHT);
  };

  return (
    <Container>
      <Joystick
        size={128}
        sticky={false}
        stickSize={44}
        baseColor={theme.colorBelow0}
        stickColor={theme.colorBelow1}
        throttle={300}
        start={handleReset}
        move={handleMove}
        stop={handleReset}
      />
    </Container>
  );
};

export default CameraKeys;
