import MobileControllerUiLandscape from "./MobileController.ui.landscape";

export type Props = {
  onTouchStart: (key: number) => void;
  onTouchEnd: (key: number) => void;
  onPhotoClick?: () => void;
  onSettingsClick?: () => void;
  hideActionBarButtons?: boolean;
};

const MobileControllerUi: React.FC<Props> = (props) => {
  return <MobileControllerUiLandscape {...props} />;
};

export default MobileControllerUi;
