import debounce from "lodash/debounce";
import { POINTER_LOCK_MS_DELAY } from "../../../../common/constants/configs.constant";
import {
  isChrome,
  isDesktop,
  isEdge,
  isFirefox,
} from "../../../../common/constants/flags.constant";
import { log, logWarn } from "../../../../common/util/logger";
import { sendInputData } from "../webRtcConnection";
import { sendGameMessage } from "../webRtcMessageHandlers";
import { OnScreenKeyboardCommand } from "../webRtcTypes";
import { MessageType, inputOptions } from "./constants";
import { clickRipples } from "./utils";

type XY = {
  x: number;
  y: number;
};
type NormalizedXY = {
  inRange: boolean;
  x: number;
  y: number;
};
export interface EpicHTMLVideoElement extends HTMLVideoElement {
  // to rewire touch events to mouse events.
  pressMouseButtons: (e: MouseEvent) => void;
  releaseMouseButtons: (e: MouseEvent) => void;
  // Super hacky support for mouse scroll events by Epic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onmousewheel: (e: any) => void;
}

let playerElementClientRect: ClientRect;
let editTextButton: HTMLButtonElement;

// let styleWidth = 600;
// let styleHeight = 400;
const playerSizeChanged = (videoElement: HTMLVideoElement) => {
  playerElementClientRect = videoElement.getBoundingClientRect();
  setupNormalizeAndQuantize(videoElement);
  // styleWidth = width;
  // styleHeight = height;
};
let hasMouseEntered = false;
export const debouncedPlayerSizeChanged = debounce(playerSizeChanged, 500, {
  leading: true,
});

/**
 * This function calculates aspect ratios, selects appropriate mouse position
 * normalization and quantization methods based on these ratios, and handles
 * out-of-range cases. It also logs debug information. This setup ensures
 * accurate mouse position data transformation for server communication.
 */
function setupNormalizeAndQuantize(videoElement: HTMLVideoElement): {
  normalizeAndQuantizeUnsigned: (x: number, y: number) => NormalizedXY;
  normalizeAndQuantizeSigned: (x: number, y: number) => XY;
  unquantizeAndDenormalizeUnsigned: (x: number, y: number) => XY;
} {
  const { videoHeight, videoWidth, clientWidth, clientHeight } = videoElement;
  const playerAspectRatio = clientHeight / clientWidth;
  const videoAspectRatio = videoHeight / videoWidth;

  log(
    "CONTROLS_SETUP",
    `P: ${clientHeight} / ${clientWidth} = ${playerAspectRatio}; V: ${videoHeight} / ${videoWidth} = ${videoAspectRatio}`
  );
  if (isNaN(videoAspectRatio)) {
    logWarn("CONTROLS_SETUP", "video size isn't loaded yet!");
  }

  // Unsigned XY positions are the ratio (0.0..1.0) along a viewport axis,
  // quantized into an uint16 (0..65536).
  // Signed XY deltas are the ratio (-1.0..1.0) along a viewport axis,
  // quantized into an int16 (-32767..32767).
  // This allows the browser viewport and client viewport to have a different
  // size.
  // Hack: Currently we set an out-of-range position to an extreme (65535)
  // as we can't yet accurately detect mouse enter and leave events
  // precisely inside a video with an aspect ratio which causes mattes.
  if (playerAspectRatio < videoAspectRatio) {
    log(
      "CONTROLS_SETUP",
      "A) Setup Normalize and Quantize for playerAspectRatio > videoAspectRatio"
    );

    const ratio = playerAspectRatio / videoAspectRatio;
    // Unsigned.
    const normalizeAndQuantizeUnsigned: (
      x: number,
      y: number
    ) => NormalizedXY = (x, y) => {
      const normalizedX = x / clientWidth;
      const normalizedY = ratio * (y / clientHeight - 0.5) + 0.5;

      if (
        normalizedX < 0.0 ||
        normalizedX > 1.0 ||
        normalizedY < 0.0 ||
        normalizedY > 1.0
      ) {
        return {
          inRange: false,
          x: 65535,
          y: 65535,
        };
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536,
        };
      }
    };
    const unquantizeAndDenormalizeUnsigned: (x: number, y: number) => XY = (
      x,
      y
    ) => {
      const normalizedX = x / 65536;
      const normalizedY = (y / 65536 - 0.5) / ratio + 0.5;
      return {
        x: normalizedX * clientWidth,
        y: normalizedY * clientHeight,
      };
    };
    // Signed.
    const normalizeAndQuantizeSigned: (x: number, y: number) => XY = (x, y) => {
      const normalizedX = x / (0.5 * clientWidth);
      const normalizedY = (ratio * y) / (0.5 * clientHeight);
      return {
        x: normalizedX * 32767,
        y: normalizedY * 32767,
      };
    };
    return {
      normalizeAndQuantizeUnsigned,
      normalizeAndQuantizeSigned,
      unquantizeAndDenormalizeUnsigned,
    };
  } else {
    log(
      "CONTROLS_SETUP",
      "B) Setup Normalize and Quantize for playerAspectRatio <= videoAspectRatio"
    );
    const ratio = videoAspectRatio / playerAspectRatio;
    // Unsigned.
    const normalizeAndQuantizeUnsigned: (
      x: number,
      y: number
    ) => NormalizedXY = (x, y) => {
      const normalizedX = ratio * (x / clientWidth - 0.5) + 0.5;
      const normalizedY = y / clientHeight;

      if (
        normalizedX < 0.0 ||
        normalizedX > 1.0 ||
        normalizedY < 0.0 ||
        normalizedY > 1.0
      ) {
        return {
          inRange: false,
          x: 65535,
          y: 65535,
        };
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536,
        };
      }
    };
    const unquantizeAndDenormalizeUnsigned: (x: number, y: number) => XY = (
      x,
      y
    ) => {
      const normalizedX = (x / 65536 - 0.5) / ratio + 0.5;
      const normalizedY = y / 65536;
      return {
        x: normalizedX * clientWidth,
        y: normalizedY * clientHeight,
      };
    };
    // Signed.
    const normalizeAndQuantizeSigned: (x: number, y: number) => XY = (x, y) => {
      const normalizedX = (ratio * x) / (0.5 * clientWidth);
      const normalizedY = y / (0.5 * clientHeight);
      return {
        x: normalizedX * 32767,
        y: normalizedY * 32767,
      };
    };
    return {
      normalizeAndQuantizeUnsigned,
      normalizeAndQuantizeSigned,
      unquantizeAndDenormalizeUnsigned,
    };
  }
}

function emitMouseMove(
  x: number,
  y: number,
  deltaX: number,
  deltaY: number,
  video: EpicHTMLVideoElement
) {
  log("MOUSE", `x: ${x}, y:${y}, dX: ${deltaX}, dY: ${deltaY}`);
  const { normalizeAndQuantizeUnsigned, normalizeAndQuantizeSigned } =
    setupNormalizeAndQuantize(video);
  const coord = normalizeAndQuantizeUnsigned(x, y);
  const delta = normalizeAndQuantizeSigned(deltaX, deltaY);
  const Data = new DataView(new ArrayBuffer(9));
  if (!coord || !delta) {
    logWarn("MOUSE", "Normalize and Quantize returned undefined!");
    return;
  }
  Data.setUint8(0, MessageType.MouseMove);
  Data.setUint16(1, coord.x, true);
  Data.setUint16(3, coord.y, true);
  Data.setInt16(5, delta.x, true);
  Data.setInt16(7, delta.y, true);
  sendInputData(Data.buffer);
}

function emitMouseDown(
  button: number,
  x: number,
  y: number,
  video: EpicHTMLVideoElement
) {
  log("MOUSE", `mouse button ${button} down at (${x}, ${y})`);
  // Dirty hack to fix this: https://trello.com/c/539NBx5w/125-fix-mouse-click
  // But Epic does nearly the same to fix touch events...
  if (!hasMouseEntered) {
    logWarn("MOUSE", "Faking a mouse enter event.");
    emitMouseEnter();
  }
  const { normalizeAndQuantizeUnsigned } = setupNormalizeAndQuantize(video);
  const coord = normalizeAndQuantizeUnsigned(x, y);
  const Data = new DataView(new ArrayBuffer(6));

  if (!coord) {
    logWarn("MOUSE", "Normalize and Quantize returned undefined!");
    return;
  }
  Data.setUint8(0, MessageType.MouseDown);
  Data.setUint8(1, button);
  Data.setUint16(2, coord.x, true);
  Data.setUint16(4, coord.y, true);
  sendInputData(Data.buffer);
  const parent = document.getElementById("clickRipples");
  if (parent) {
    clickRipples(parent, x, y);
  }
}

function emitMouseUp(
  button: number,
  x: number,
  y: number,
  video: EpicHTMLVideoElement
) {
  log("MOUSE", `mouse button ${button} up at (${x}, ${y})`);
  const { normalizeAndQuantizeUnsigned } = setupNormalizeAndQuantize(video);
  const coord = normalizeAndQuantizeUnsigned(x, y);
  if (!coord) {
    logWarn("MOUSE", "Normalize and Quantize returned undefined!");
    return;
  }
  const Data = new DataView(new ArrayBuffer(6));
  Data.setUint8(0, MessageType.MouseUp);
  Data.setUint8(1, button);
  Data.setUint16(2, coord.x, true);
  Data.setUint16(4, coord.y, true);
  sendInputData(Data.buffer);
}

function emitMouseWheel(
  delta: number,
  x: number,
  y: number,
  video: EpicHTMLVideoElement
) {
  log("MOUSE", `mouse wheel with delta ${delta} at (${x}, ${y})`);
  const { normalizeAndQuantizeUnsigned } = setupNormalizeAndQuantize(video);
  const coord = normalizeAndQuantizeUnsigned(x, y);
  if (!coord) {
    logWarn("MOUSE", "Normalize and Quantize returned undefined!");
    return;
  }
  const Data = new DataView(new ArrayBuffer(7));
  Data.setUint8(0, MessageType.MouseWheel);
  Data.setInt16(1, delta, true);
  Data.setUint16(3, coord.x, true);
  Data.setUint16(5, coord.y, true);
  sendInputData(Data.buffer);
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const MouseButton = {
  MainButton: 0, // Left button.
  AuxiliaryButton: 1, // Wheel button.
  SecondaryButton: 2, // Right button.
  FourthButton: 3, // Browser Back button.
  FifthButton: 4, // Browser Forward button.
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
const MouseButtonsMask = {
  PrimaryButton: 1, // Left button.
  SecondaryButton: 2, // Right button.
  AuxiliaryButton: 4, // Wheel button.
  FourthButton: 8, // Browser Back button.
  FifthButton: 16, // Browser Forward button.
};

// If the user has any mouse buttons pressed then release them.
function releaseMouseButtons(
  buttons: number,
  x: number,
  y: number,
  video: EpicHTMLVideoElement
) {
  if (buttons & MouseButtonsMask.PrimaryButton) {
    emitMouseUp(MouseButton.MainButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.SecondaryButton) {
    emitMouseUp(MouseButton.SecondaryButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.AuxiliaryButton) {
    emitMouseUp(MouseButton.AuxiliaryButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.FourthButton) {
    emitMouseUp(MouseButton.FourthButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.FifthButton) {
    emitMouseUp(MouseButton.FifthButton, x, y, video);
  }
}

// If the user has any mouse buttons pressed then press them again.
// This is only for indirect/fake click events like "onenter".
function pressMouseButtons(
  buttons: number,
  x: number,
  y: number,
  video: EpicHTMLVideoElement
) {
  if (buttons & MouseButtonsMask.PrimaryButton) {
    emitMouseDown(MouseButton.MainButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.SecondaryButton) {
    emitMouseDown(MouseButton.SecondaryButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.AuxiliaryButton) {
    emitMouseDown(MouseButton.AuxiliaryButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.FourthButton) {
    emitMouseDown(MouseButton.FourthButton, x, y, video);
  }
  if (buttons & MouseButtonsMask.FifthButton) {
    emitMouseDown(MouseButton.FifthButton, x, y, video);
  }
}

export function showOnScreenKeyboard(
  command: OnScreenKeyboardCommand,
  video: EpicHTMLVideoElement
) {
  if (!editTextButton) return;
  if (command.showOnScreenKeyboard) {
    // Show the 'edit text' button.
    editTextButton.classList.remove("hiddenState");
    // Place the 'edit text' button near the UE4 input widget.
    const { unquantizeAndDenormalizeUnsigned } =
      setupNormalizeAndQuantize(video);
    const pos = unquantizeAndDenormalizeUnsigned(command.x, command.y);
    editTextButton.style.top = pos.y.toString() + "px";
    editTextButton.style.left = (pos.x - 40).toString() + "px";
  } else {
    // Hide the 'edit text' button.
    editTextButton.classList.add("hiddenState");
  }
}

const emitMouseEnter = () => {
  const Data = new DataView(new ArrayBuffer(1));
  Data.setUint8(0, MessageType.MouseEnter);
  sendInputData(Data.buffer);
  hasMouseEntered = true;
};

function registerPointerLock(
  playerElement: EpicHTMLVideoElement,
  hasPointerLock: boolean
) {
  let pointerLockID: number;

  playerElement.requestPointerLock =
    playerElement.requestPointerLock ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (playerElement as any).mozRequestPointerLock;
  document.exitPointerLock =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.exitPointerLock || (document as any).mozExitPointerLock;

  function delayedPointerLock() {
    pointerLockID = window.setTimeout(
      () => {
        log("POINTER_LOCK", "Request pointer lock");
        playerElement.requestPointerLock();
      },
      hasPointerLock ? 0 : POINTER_LOCK_MS_DELAY
    );
  }

  function clearPointerLock() {
    window.clearTimeout(pointerLockID);
  }

  const onmousedown = (e: MouseEvent) => {
    if (
      e.button === MouseButton.MainButton ||
      e.button === MouseButton.SecondaryButton
    ) {
      delayedPointerLock();
    }
    playerElement?.focus();
  };
  playerElement.addEventListener("mousedown", onmousedown, false);
  registeredMouseEvents.push({
    type: "mousedown",
    handler: onmousedown,
    elem: playerElement,
  });

  if (hasPointerLock) {
    const onescape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        log("POINTER_LOCK", "Escape release Pointer Lock");
        clearPointerLock();
        document.exitPointerLock();
      }
    };
    playerElement.addEventListener("keydown", onescape, false);
    registeredMouseEvents.push({
      type: "keydown",
      handler: onescape,
      elem: playerElement,
    });
  } else {
    const onmouseup = (e: MouseEvent) => {
      if (
        e.button === MouseButton.MainButton ||
        e.button === MouseButton.SecondaryButton
      ) {
        log("POINTER_LOCK", "Release Pointer Lock");
        clearPointerLock();
        document.exitPointerLock();
      }
    };
    playerElement.addEventListener("mouseup", onmouseup, false);
    registeredMouseEvents.push({
      type: "mouseup",
      handler: onmouseup,
      elem: playerElement,
    });
  }

  const onmouseout = () => {
    log("POINTER_LOCK", "Open Panel release Pointer Lock");
    clearPointerLock();
    document.exitPointerLock();
  };
  playerElement.addEventListener("mouseout", onmouseout, false);
}

// A hovering mouse works by the user clicking the mouse button when they want
// the cursor to have an effect over the video. Otherwise, the cursor just
// passes over the browser.
function registerHoveringMouseEvents(
  videoElement: EpicHTMLVideoElement,
  hasPointerLock: boolean
) {
  // styleCursor = "none"; // We will rely on UE4 client's software cursor.
  //styleCursor = 'default';  // Showing cursor

  const onmousemove = (e: MouseEvent) => {
    // If pointer is locked, these events are not relevant.
    // So we are sending them only when the pointer is not locked.
    if (!hasPointerLock || document.pointerLockElement === videoElement) {
      emitMouseMove(
        e.offsetX,
        e.offsetY,
        e.movementX,
        e.movementY,
        videoElement
      );
    }
    e.preventDefault();
  };
  videoElement.addEventListener("mousemove", onmousemove);
  registeredMouseEvents.push({
    elem: videoElement,
    type: "mousemove",
    handler: onmousemove,
  });

  videoElement.onmousedown = function (e) {
    emitMouseDown(e.button, e.offsetX, e.offsetY, videoElement);
    e.preventDefault();
  };

  videoElement.onmouseup = function (e) {
    emitMouseUp(e.button, e.offsetX, e.offsetY, videoElement);
    e.preventDefault();
  };

  // When the context menu is shown then it is safest to release the button
  // which was pressed when the event happened. This will guarantee we will
  // get at least one mouse up corresponding to a mouse down event. Otherwise,
  // the mouse can get stuck.
  // https://github.com/facebook/react/issues/5531
  const oncontextmenu = (e: MouseEvent) => {
    emitMouseUp(e.button, e.offsetX, e.offsetY, videoElement);
    e.preventDefault();
  };
  videoElement.addEventListener("contextmenu", oncontextmenu);
  registeredMouseEvents.push({
    elem: videoElement,
    type: "contextmenu",
    handler: oncontextmenu,
  });

  if ("onmousewheel" in videoElement) {
    videoElement.onmousewheel = function (e) {
      emitMouseWheel(e.wheelDelta, e.offsetX, e.offsetY, videoElement);
      e.preventDefault();
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domMouseScroll = (e: any) => {
      emitMouseWheel(e.detail * -120, e.offsetX, e.offsetY, videoElement);
      e.preventDefault();
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (videoElement as any).addEventListener(
      "DOMMouseScroll",
      domMouseScroll,
      false
    );
    registeredMouseEvents.push({
      type: "DOMMouseScroll",
      handler: domMouseScroll,
      elem: videoElement,
    });
  }

  videoElement.pressMouseButtons = function (e) {
    pressMouseButtons(e.buttons, e.offsetX, e.offsetY, videoElement);
  };

  videoElement.releaseMouseButtons = function (e) {
    releaseMouseButtons(e.buttons, e.offsetX, e.offsetY, videoElement);
  };
}

/**
 * To be able to call registerMouseEvents multiple times to switch between
 * modes, we need to be able to unregister previous listeners
 *
 */
const registeredMouseEvents: Array<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (...args: any) => void;
  elem: HTMLElement | HTMLDocument;
  type: string;
}> = [];

export const registerMouseEvents = (
  video: HTMLVideoElement,
  hasPointerLock: boolean
) => {
  for (const registered of registeredMouseEvents)
    registered.elem?.removeEventListener(registered.type, registered.handler);

  hasMouseEntered = false;
  const playerElement = video as EpicHTMLVideoElement;
  playerElement.onmouseenter = function (e) {
    log("MOUSE", "mouse enter");
    emitMouseEnter();
    // Resend mouse button events.
    playerElement.pressMouseButtons(e);
  };
  playerElement.onmouseleave = function (e) {
    log("MOUSE", "mouse leave");
    const Data = new DataView(new ArrayBuffer(1));
    Data.setUint8(0, MessageType.MouseLeave);
    sendInputData(Data.buffer);
    playerElement.releaseMouseButtons(e);
  };
  const isChromiumDesktop =
    isDesktop && (isChrome || isEdge || (hasPointerLock && isFirefox));
  if (isChromiumDesktop) {
    registerPointerLock(video as EpicHTMLVideoElement, hasPointerLock);
  }
  registerHoveringMouseEvents(
    video as EpicHTMLVideoElement,
    isChromiumDesktop ? hasPointerLock : false
  );
  registerTouchEvents(video as EpicHTMLVideoElement);
};

let didTouch = false;
const fakeTouchHappened = () => {
  if (!didTouch) {
    didTouch = true;
    sendGameMessage({
      type: "DidFakeTouch",
    });
  }
};

function registerTouchEvents(playerElement: EpicHTMLVideoElement) {
  // We need to assign a unique identifier to each finger.
  // We do this by mapping each Touch object to the identifier.
  const fingers = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
  const fingerIds: { [index: number]: number } = {};

  function rememberTouch(touch: Touch) {
    const finger = fingers.pop();
    if (finger === undefined) {
      logWarn("MOUSE", "exhausted touch identifiers");
      // Slightly modified the original Epic behavior below:
    } else fingerIds[touch.identifier] = finger;
  }

  function forgetTouch(touch: Touch) {
    fingers.push(fingerIds[touch.identifier]);
    delete fingerIds[touch.identifier];
  }

  function emitTouchData(type: number, touches: TouchList) {
    const data = new DataView(new ArrayBuffer(2 + 6 * touches.length));
    data.setUint8(0, type);
    data.setUint8(1, touches.length);
    let byte = 2;
    for (const touch of Array.from(touches)) {
      const x = touch.clientX - playerElement.offsetLeft;
      const y = touch.clientY - playerElement.offsetTop;
      log("MOUSE", `F${fingerIds[touch.identifier]}=(${x}, ${y})`);
      const { normalizeAndQuantizeUnsigned } =
        setupNormalizeAndQuantize(playerElement);
      const coord = normalizeAndQuantizeUnsigned(x, y);
      if (!coord) {
        logWarn("MOUSE", "Normalize and Quantize returned undefined!");
        return;
      }
      data.setUint16(byte, coord.x, true);
      byte += 2;
      data.setUint16(byte, coord.y, true);
      byte += 2;
      data.setUint8(byte, fingerIds[touch.identifier]); // true as 3rd argument originally
      byte += 1;
      // force is between 0.0 and 1.0 so quantize into byte.
      data.setUint8(byte, 255 * touch.force); // true as 3rd argument originally
      byte += 1;
    }
    sendInputData(data.buffer);
  }

  if (inputOptions.fakeMouseWithTouches) {
    let finger: undefined | { id: number; x: number; y: number };

    playerElement.ontouchstart = function (e) {
      if (finger === undefined) {
        const firstTouch = e.changedTouches[0];
        finger = {
          id: firstTouch.identifier,
          x: firstTouch.clientX - playerElementClientRect.left,
          y: firstTouch.clientY - playerElementClientRect.top,
        };
        // Hack: Mouse events require enter and leave, so we just
        // enter and leave manually with each touch as this event
        // is not fired with a touch device.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (playerElement.onmouseenter) playerElement.onmouseenter(e as any);
        emitMouseDown(
          MouseButton.MainButton,
          finger.x,
          finger.y,
          playerElement
        );
        fakeTouchHappened();
      }
      e.preventDefault();
    };

    playerElement.ontouchend = function (e) {
      for (const touch of Array.from(e.changedTouches)) {
        if (touch.identifier === finger?.id) {
          const x = touch.clientX - playerElementClientRect.left;
          const y = touch.clientY - playerElementClientRect.top;
          emitMouseUp(MouseButton.MainButton, x, y, playerElement);
          // Hack: Manual mouse leave event.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (playerElement.onmouseleave) playerElement.onmouseleave(e as any);
          finger = undefined;
          break;
        }
      }
      e.preventDefault();
    };

    playerElement.ontouchmove = function (e) {
      for (const touch of Array.from(e.changedTouches)) {
        if (touch.identifier === finger?.id) {
          const x = touch.clientX - playerElementClientRect.left;
          const y = touch.clientY - playerElementClientRect.top;
          emitMouseMove(x, y, x - finger.x, y - finger.y, playerElement);
          finger.x = x;
          finger.y = y;
          break;
        }
      }
      e.preventDefault();
    };

    playerElement.ontouchcancel = function (e) {
      for (const touch of Array.from(e.changedTouches)) {
        if (touch.identifier === finger?.id) {
          // This way we tell unreal that the mouse button is up.
          const x = touch.clientX - playerElementClientRect.left;
          const y = touch.clientY - playerElementClientRect.top;
          emitMouseUp(MouseButton.MainButton, x, y, playerElement);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (playerElement.onmouseleave) playerElement.onmouseleave(e as any);
          finger = undefined;
          break;
        }
      }
      e.preventDefault();
    };
  } else {
    playerElement.ontouchstart = function (e) {
      // Assign a unique identifier to each touch.
      for (let t = 0; t < e.changedTouches.length; t++) {
        rememberTouch(e.changedTouches[t]);
      }

      log("MOUSE", "touch start");
      emitTouchData(MessageType.TouchStart, e.changedTouches);
      e.preventDefault();
    };

    playerElement.ontouchend = function (e) {
      log("MOUSE", "touch end");
      emitTouchData(MessageType.TouchEnd, e.changedTouches);

      // Re-cycle unique identifiers previously assigned to each touch.
      for (let t = 0; t < e.changedTouches.length; t++) {
        forgetTouch(e.changedTouches[t]);
      }
      e.preventDefault();
    };

    playerElement.ontouchmove = function (e) {
      log("MOUSE", "touch move");
      emitTouchData(MessageType.TouchMove, e.touches);
      e.preventDefault();
    };
  }
}
