import { ActionElementDto } from "@journee-live/gyarados/axios/esm";
import { css, styled, useTheme } from "styled-components";
import { sendGameMessage } from "../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { steps, zIndex } from "../../../app/style/theme";
import Button from "../../../componentsLibrary/atoms/Button";
import { IconName } from "../../../componentsLibrary/atoms/Icon";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { ActionElementState } from "../ActionElements.slice";
import ActionElementIcon from "./ActionElementIcon";

const ImageMenuDrawer = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 12px 0;
  height: 96px;
  border-radius: ${steps.borderRadius.b30};
  background-color: ${(p) => p.theme.colorBelow1};
  backdrop-filter: blur(${(p) => p.theme.blurLight});
  z-index: ${zIndex.panels};

  position: absolute;
  bottom: 74px;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(p) => (p.$isOpen ? 1 : 0)};
  pointer-events: ${(p) => (p.$isOpen ? "auto" : "none")};
  transition: opacity 300ms;
  transition-timing-function: ease-in-out;
  transform-origin: center center;
`;

const ImageMenuItemWrapper = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  cursor: pointer;
  pointer-events: ${(p) => (p.$isOpen ? "auto" : "none")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4px 0 0 0;
`;

const ImageMenuItem = styled.div<{ $imageUrl?: string }>`
  width: 72px;
  height: 72px;
  box-shadow: inset 0 0 0 1px ${(p) => p.theme.colorAbove1};
  border-radius: ${steps.borderRadius.b20};
  background-color: transparent;
  ${(p) =>
    p.$imageUrl &&
    css`
      background-image: url(${p.$imageUrl});
      background-size: cover;
      background-position: center;
    `}
  display: flex;
  align-items: flex-end;
  padding: 4px 8px;
  position: relative;
  overflow: hidden;
`;

const ImageMenuItemGradient = styled.div`
  width: 100%;
  background: linear-gradient(to top, black, transparent);
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40%;
`;

const ImageMenuItemSelectedIndicator = styled.div<{ $isSelected?: boolean }>`
  width: 100%;
  height: 4px;
  margin-top: 4px;
  border-radius: ${steps.borderRadius.b10};
  background-color: ${(p) => p.theme.colorAbove1};
  opacity: ${(p) => (p.$isSelected ? 1 : 0)};
  transition: opacity 300ms;
  transition-timing-function: ease-in-out;
  transform-origin: center center;
`;

const ActionElementImageMenu: React.FC<{
  actionElementConfig: ActionElementDto;
  state?: ActionElementState;
}> = ({ actionElementConfig, state }) => {
  const theme = useTheme();
  const isOpen = state?.options?.open;
  const selectedOption = state?.value;
  // Filter options in Game wants to show only a subset of the options
  const environmentOptions = actionElementConfig.options;
  const stateOptions = state?.options?.items;
  const options = stateOptions?.length
    ? environmentOptions?.filter((option) =>
        stateOptions.includes(option.value)
      )
    : environmentOptions;

  // Sort options by order
  options?.sort((a, b) => a.order - b.order);

  return (
    <>
      {Boolean(options?.length) && (
        <ImageMenuDrawer $isOpen={isOpen}>
          {options?.map((option) => (
            <ImageMenuItemWrapper
              $isOpen={isOpen}
              key={option.value}
              onClick={() => {
                sendGameMessage({
                  type: "ActionElementEvent",
                  actionElementId: actionElementConfig.actionElementId,
                  event: "change",
                  value: option.value,
                });
              }}
            >
              <ImageMenuItem $imageUrl={option.image}>
                <ImageMenuItemGradient />
                <Typo.Note
                  color={theme.colorAboveBrand}
                  style={{ zIndex: 0, opacity: 0.8 }}
                >
                  {option.label}
                </Typo.Note>
              </ImageMenuItem>
              <ImageMenuItemSelectedIndicator
                $isSelected={option.value === selectedOption}
              />
            </ImageMenuItemWrapper>
          ))}
        </ImageMenuDrawer>
      )}
      <Button.Subtle
        onClick={() => {
          sendGameMessage({
            type: "ActionElementEvent",
            actionElementId: actionElementConfig.actionElementId,
            event: "click",
          });
        }}
        override={{
          height: "44px",
          backgroundColor: state?.options?.open
            ? theme.colorAbove0
            : "transparent",
        }}
        iconLeft={
          <ActionElementIcon
            name={actionElementConfig.icon as IconName}
            size="20px"
          />
        }
      >
        <Typo.Body
          weight={600}
          style={{ marginLeft: "4px" }}
          color={theme.colorAbove4}
        >
          {actionElementConfig.name}
        </Typo.Body>
      </Button.Subtle>
    </>
  );
};

export default ActionElementImageMenu;
